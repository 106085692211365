<template>
  <section class="rims-config">
    <rimconfig-breadcrumb
      :breadcrumb-items="breadcrumbItems"
      @set-car-selector-step="setCarSelectorStep"
    />
    <div
      class="content"
      :style="{
        backgroundImage: `url(${require('@/assets/images/Cover1.png')})`
      }"
    >
      <div class="overlay">
        <div class="textbox">
          <div class="first">
            <h1>{{ $t('langkey.configurator-carousel-head1') }}</h1>
          </div>
        </div>
        <base-rimconfig-car-selector
          @select-version="onSelectCarVersion"
        />
      </div>
    </div>
    <base-rimconfig-top-brand show-full />
  </section>
</template>

<style lang="scss">
@import '@/styles/pages/rims-config.scss';
</style>

<script>
import RimconfigBreadcrumb from '@/components/RimconfigBreadcrumb';
import { breadcrumbItems } from './configs.js';
import { mapState } from 'vuex';

export default {
  components: {
    RimconfigBreadcrumb,
  },
  data() {
    return {
      search: '',
      showSearchBar: false,
    };
  },
  computed: {
    ...mapState({
      step: (state) => state.rimconfigCarSelector.step,
    }),
    breadcrumbItems() {
      switch (this.step) {
      case 1:
        return breadcrumbItems.slice(0,1);
      case 2:
        return breadcrumbItems.slice(0,2);
      case 3:
        return breadcrumbItems.slice(0,3);
      case 4:
        return breadcrumbItems.slice(0,4);
      case 'kba-versions':
        return breadcrumbItems.slice(0,1).concat(breadcrumbItems.slice(3));
      default:
        return breadcrumbItems;
      }
    },
  },
  mounted() {
    this.$store.dispatch('rimconfigCarSelector/setStep', this.step);
    this.$store.dispatch('rimconfigCarConfigurator/setSelectConfiguratorStep');
  },
  methods: {
    onSearch() {
      if (this.search === '') {
        this.showSearchBar = !this.showSearchBar;
      }
    },
    async onSelectCarVersion(value) {
      try {
        await this.$store.dispatch('rimconfigCarSelector/setCarHistory', value.tag);
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: true,
        });
        await this.$store.dispatch('rimconfigCarSelector/getCarHistory');
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
        this.$router.push(
          this.$i18nRoute({
            name: 'rims-config-configurator_carTag',
            params: {
              carTag: value.tag,
            },
          }),
        );
      }
    },
    hideSearchBar() {
      if (this.search === '') {
        this.showSearchBar = false;
      }
    },
    clearSearch() {
      this.search = '';
    },
    setCarSelectorStep(step) {
      this.$store.dispatch('rimconfigCarSelector/setStep', step);
      this.$store.dispatch('rimconfigCarSelector/setSearchByKbaNumber', false);
    },
  },
};
</script>